.edit-user-modal {
  transform: translate(-50%, -50%);
  .select__wrapper {
    margin-bottom: 24px;
    position: relative;
    .input__error {
      bottom: -16px;
    }
  }
  .city-edit {
    .input__error {
      bottom: 5px;
    }
  }
  &__margin-top {
    margin-top: 20px;
  }
  &__phone {
    margin-bottom: 24px;
    position: relative;
    z-index: 9999999999999999;
    &--nospace {
      margin-bottom: 0;
    }
    .select__wrapper {
      position: absolute;
      z-index: 2;
      width: 120px;
      left: 4px;
      top: -12px;
      // gap: 0;
      .label {
        display: none;
      }
      .input__error {
        bottom: -26px;
      }
      .select__indicator {
        padding: 0;
        color: $purple400;
      }
      .select__control {
        background-color: #eeedff;
        border: none;
      }
      div .select__value-container {
        padding-right: 0;
        height: unset;
      }
      .select__option-container {
        color: $navy300;
        font-weight: 400;
        font-size: 16px;
        line-height: 126.9%;
        .svg--flag {
          min-width: 27px;
        }
      }
    }
  }
  &__buttons {
    display: flex;
    gap: 8px;
    margin-top: 40px;
    .btn {
      height: 40px;
    }
    @include mobile-only {
      margin-top: auto;
      margin-bottom: 20px;
    }
  }
  &__form {
    max-width: 400px;
    width: 100%;
    > .input__wrapper:first-child {
      .input {
        margin-bottom: 32px;
      }
      .input__error {
        bottom: 18px;
      }
    }
    .center {
      text-align: center;
    }
    .mobile-delete {
      margin-bottom: 20px;
    }
  }
  &__header {
    font-weight: 700;
    font-size: 20px;
    line-height: 126.9%;
    color: $navy700;
    margin-bottom: 40px;
  }
}
.edit-user-modall {
  .select__wrapper {
    margin-bottom: 0;
  }
}

.css-1nmdiq5-menu {
  z-index: 99999999999 !important;
  display: block;
  position: relative;
}


.edit-avatar {
  &--error {
    font-size: 14px;
    color:red;
    margin-top: -20px;
  }
}