.bar {
  background-color: #EBEBFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 64px;
  margin-bottom: 10px;
  padding-left: 22px;
  padding-right: 12px;
  border-radius: 9px;
  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 126.9%;
    color: $navy600;
  }
  &__sub-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 126.9%;
    color: $navy400;
    margin-top: 2px;
  }
  .svg--chevron {
    transform: rotate(180deg);
    path {
      stroke:$purple500;
    }
  }
  &--onClick {
    cursor: pointer;
  }
  &--button {
    padding-right: 20px;
  }
  &__button {
    height: 32px;
    max-width: 132px;
    font-size: 13px;
  }
}
